import React from 'react'
import PropTypes from 'prop-types'

import { buildComponentsFromHtmlAst } from '../../utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './EventStyles.scss'

const months = [ "January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December" ];

const getTimeStr = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  let amPm = hours > 11 ? 'PM' : 'AM'

  hours = hours % 12

  return `${hours}:${minutes} ${amPm}`
}

const Event = (props) => {
  const {
    title,
    location,
    description,
    flyer,
    dateTime,
    durationMinutes
  } = props
  let flyerExists = false
  if (flyer && flyer.description !== 'dummy') {
    flyerExists = true
  }

  let date = new Date(dateTime)

  const dateBox = (
    <div className="date-box">
      <span className="month">{months[date.getMonth()].substring(0,3)}</span>
      <span className="date">{date.getDate()}</span>
    </div>
  )
  return (
    <div className="event">
      <div className="date-box-col">
        {dateBox}
      </div>
      <div className="text-wrapper">
        <div className="event-header">
          { dateBox }
          <h2>{title}</h2>
          
        </div>
        <div className="info-lines">
          <div className="event-info-line">
            <FontAwesomeIcon className="event-info-icon" icon={['far', 'clock']} />
            <span>{getTimeStr(date)}</span>
          </div>

          <div className="event-info-line">
            <FontAwesomeIcon className="event-info-icon" icon={'hourglass-start'} />
            <span>{ durationMinutes } minutes</span>
          </div>
            { flyerExists &&
              <div className="event-info-line">
                <FontAwesomeIcon className="event-info-icon" icon={['far', 'file-pdf']} />
                <span><a href={`https:${flyer.file.url}`}>{flyer.title}</a></span>
              </div>
            }
          <div className="event-info-line">
            <FontAwesomeIcon className="event-info-icon" icon={'map-marker-alt'} />
            <span>{ location }</span>
          </div>

        </div>
        { description && buildComponentsFromHtmlAst(description.childMarkdownRemark.htmlAst.children) }
      </div>
    </div>
  )
}

Event.defaultProps = {
  flyer: null,
  description: null,
  index: 0,
}

Event.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  description: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      htmlAst: PropTypes.shape({
        children: PropTypes.array
      })
    })
  }),
  flyer: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  durationMinutes: PropTypes.number.isRequired,
  index: PropTypes.number,
  dateTime: PropTypes.string.isRequired,
}

export default Event