import React from 'react'
import { graphql } from 'gatsby'

import { buildComponentsFromHtmlAst } from '../utils/helpers'

import Layout from '../components/layout/Layout'
import SEO from '../components/seo'

import PageHeader from '../components/pageHeader/PageHeader'
import Event from '../components/event/Event'

import './styles/EventsPageStyles.scss'

const EventsPage = ({ data }) => {
  const {
    contentfulEventsPage,
    allContentfulEvent
  } = data
  const {
    navbar,
    title,
    pageIntroText,
    seo
  } = contentfulEventsPage
  return (
    <Layout navbarData={navbar}>
      <SEO { ...seo } />
      <PageHeader title={title} />
      <div className="section event">
        <div className="content-wrapper">
          
        { pageIntroText && buildComponentsFromHtmlAst(pageIntroText.childMarkdownRemark.htmlAst.children) }
      
        </div>
      </div>

      {
        allContentfulEvent.edges.map(({ node }, index) => {
          return (
            <React.Fragment key={index}>
              <div className="section event">
                <div className="content-wrapper">
                  <Event index={index} key={index} {...node} />
                </div>
              </div>
              { index < allContentfulEvent.edges.length - 1 &&
                <div className="spacer-line"/>
              }
            </React.Fragment>
          )
        })
      }
    </Layout>
  )
}

export const query = graphql`
  query EventsPageQuery {
    contentfulEventsPage {
      title
      navbar {
        ...NavbarFragment
      }
      pageIntroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      seo {
        ...SeoFragment
      }
    }
    allContentfulEvent (
      filter: {
        title: {ne:"dummy"}
      }
      sort: {
        fields: dateTime
      }
    ) {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              htmlAst
            }
          }
          location
          dateTime
          
          durationMinutes
          flyer {
            title
            description
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default EventsPage
